<template>
  <div class="Brands">
    <van-list v-model="loading" style="display:flex;flex-wrap: wrap;justify-content: space-between;"
            :finished="finished" finished-text="没有更多了" @load="scroll">
    <div class="Brands_list" v-for="(item,index) in list" :key="index" @click="G_RelatedComplaints(item.id)">
      <div class="Brands_list_top">
        <div class="Brands_list_top_left">
          <img
            :src="item.image!=''?item.image:'https://dss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2165691866,2570924737&fm=26&gp=0.jpg'"
            alt="">
          <div class="y_renling" v-if="item.is_claim ==1">商</div>
        </div>
        <div class="Brands_list_top_right">
          <div class="Brands_list_top_right_t">
            <div class="name">{{ item.name }}</div>
            <div class="renling" v-if="item.is_claim ==0" @click.stop="Go_claim(item)">
              <img src="@/assets/img/renling_icon.png" alt="">
              <span>去认领</span>
            </div>
          </div>
          <div class="Brands_list_top_right_b">
            <div class="pingxing" v-if="item.efficiency_results_level== 5">
              <span>效率</span>
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
            </div>
            <div class="pingxing" v-if="item.efficiency_results_level== 4">
              <span>效率</span>
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
            </div>
            <div class="pingxing" v-if="item.efficiency_results_level== 3">
              <span>效率</span>
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
            </div>
            <div class="pingxing" v-if="item.efficiency_results_level== 2">
              <span>效率</span>
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
            </div>
            <div class="pingxing" v-if="item.efficiency_results_level== 1">
              <span>效率</span>
              <img src="@/assets/img/xingxing.png" alt="">
            </div>

            <div class="pingxing" v-if="item.service_level== 5">
              <span>满意度</span>
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
            </div>
            <div class="pingxing" v-else-if="item.service_level== 4">
              <span>满意度</span>
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
            </div>
            <div class="pingxing" v-else-if="item.service_level== 3">
              <span>满意度</span>
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
            </div>
            <div class="pingxing" v-else-if="item.service_level== 2">
              <span>满意度</span>
              <img src="@/assets/img/xingxing.png" alt="">
              <img src="@/assets/img/xingxing.png" alt="">
            </div>
            <div class="pingxing" v-else>
              <span>满意度</span>
              <img src="@/assets/img/xingxing.png" alt="">
            </div>

          </div>
        </div>
      </div>
      <div class="Brands_list_bottom">
        <div class="Brands_list_bottom_L">
          <div class="num">{{item.complaint_count}}</div>
          <div class="nums">总投诉</div>
        </div>
        <div class="Brands_list_bottom_L">
          <div class="num">{{item.complete_count}}</div>
          <div class="nums">已完成</div>
        </div>
        <div class="Brands_list_bottom_L">
          <div class="num">{{item.unfinished}}</div>
          <div class="nums">未完成</div>
        </div>
      </div>
    </div>
    </van-list>
  </div>
</template>

<script>
import Axios from "axios";
import md5 from 'js-md5';
import { manufacturer_list } from "../../api/api.js";
export default {
   metaInfo(){
     return{
        title: '品牌列表',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  data() {
    return {
      list: [],
      load: true,
      page: 2,
      loading: false,
      finished: false,
    };
  },
  methods: {
    S_bland() {
      let that = this;
      Axios({
          method: "get",
          url: "https://tsadmin.qudong.com/api/manufacturer_list_one",
          params: { 
             key:md5('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKB'),
          },
        }).then((res) => {
        //console.log(res);
        that.list = res.data.data.data;
        });
    },
    //品牌分页
    scroll() {
      var that = this;
      Axios({
          method: "get",
          url: "https://tsadmin.qudong.com/api/manufacturer_list_one",
          params: { 
             page: this.page,
             limit:10,
             key:md5('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKB'),
          },
        }).then((res) => {
          setTimeout(() => {
          this.page = this.page + 1;
          if (res.data.data.data.length > 0) {
            this.list.push(...res.data.data.data);
            this.loading = false;
          } else {
            this.finished = true;
          }
        }, 500);
        });
    },
    G_RelatedComplaints(id) {
        this.$router.push({
          name: "BrandHome",
          params: { id: id },
        });
    },
    Go_claim(OG_count) {
      let G_count = JSON.stringify(OG_count);
      let tokens = localStorage.getItem("token");
      if(tokens){
        this.$router.push({
        name: "Entry_Enterprises",
        query: { G_count: G_count },
      });
      }else{
          this.$confirm('您未登录,是否前往登录页面？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({ name: "login" });     //去登录
        }).catch(() => {         
        });
      }
    },
  },
  mounted() {
    this.S_bland();
  },
};
</script>

<style scoped>
.Brands {
  width: 900px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Brands_list {
  width: 400px;
  padding: 20px;
  background: #fff;
  margin-top: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.Brands_list_top {
  display: flex;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 15px;
}

.Brands_list_top_left img {
  width: 103px;
  height: 103px;
  border-radius: 50%;
  margin-right: 30px;
}

.Brands_list_top_left {
  position: relative;
}

.y_renling {
  width: 28px;
  height: 28px;
  background: #1f72d9;
  text-align: center;
  line-height: 28px;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  right: 35px;
  bottom: 3px;
}

.renling {
  width: 96px;
  height: 30px;
  background: #fe9025;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.renling img {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  margin-right: 6px;
}

.renling span {
  color: #ffffff;
}

.Brands_list_top_right_t {
  display: flex;
  align-items: center;
}

.Brands_list_top_right_t .name {
  width: 156px;
  color: #333333;
  font-size: 20px;
  font-weight: bold;
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.pingxing {
  display: flex;
  align-items: center;
}

.pingxing span {
  width: 50px;
  text-align: right;
  color: #666666;
  margin-right: 15px;
}

.pingxing img {
  width: 17px;
  height: 16px;
  margin-right: 6px;
}

.Brands_list_top_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Brands_list_bottom {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 20px;
}

.Brands_list_bottom_L:nth-child(1) {
  border-right: 1px solid #e8e8e8;
  margin-left: 20px;
  padding-right: 40px;
}

.Brands_list_bottom_L:nth-child(2) {
  border-right: 1px solid #e8e8e8;
  padding-right: 40px;
}

.Brands_list_bottom_L:nth-child(3) {
  margin-right: 20px;
}

.Brands_list_bottom_L .num {
  font-size: 24px;
  font-weight: bold;
  color: #1f72d9;
}

.Brands_list_bottom_L .nums {
  font-size: 15px;
  font-weight: 500;
  color: #666666;
}
>>> .van-list__finished-text {
  width: 900px;
}
>>> .van-list__loading {
  width: 900px;
}
</style>